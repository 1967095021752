import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { ListData } from '@Types/RelatedProducts';

export interface Params {
  shopProductId: number;
  listId?: number;
  isPromo?: boolean;
}
export interface Response {
  title: string;
  lists: ListData[];
}

async function getRelatedProducts({ shopProductId, listId, isPromo }: Params) {
  const searchParams = new URLSearchParams();

  searchParams.set('shopProductId', shopProductId.toString());
  if (listId) searchParams.set('listId', listId.toString());
  if (isPromo) searchParams.set('isPromo', isPromo.toString());

  const url = `/backend/shop-product/get-related-products?${searchParams.toString()}`;
  const res = await Api.queryProxi<ApiResponse<Response[]>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getRelatedProducts;
