import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { ProductPriceData } from '@Types/Product';
import type { SetData } from './getSetItem';

export interface Response {
  productImages: string[];
  url: string;
  price: ProductPriceData;
  set?: SetData;
}

export interface Params {
  products: number[];
  additional: number[];
  typeIds?: number[];
}

async function getSetPage(params: Params) {
  const url = '/backend/configurator/get-set-page';

  const res = await Api.queryProxi<ApiResponse<Response>>(url, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getSetPage;
