import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

async function fetchSamples(productId: number | string) {
  const url = `/backend/product/configurator/${productId}`;
  const res = await Api.queryProxi<any>(url);

  if (!res.data) throw new BackendError(url, res);

  return res.data;
}

export default fetchSamples;
