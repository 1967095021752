import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ParameterValueData } from '@Types/Cart';
import type { ConstructorModule } from '@Types/Constructor';
import type { ProductPriceData } from '@Types/Product';
import type { Parameter } from '@Pages/PageProduct';
import type { ApiResponse } from '@Types/Api';

export interface FabricValueData {
  fabric_id: number;
  parameter_id: number;
}

export interface Params {
  shopProductId: number;
  parameterValues: ParameterValueData[];
  fabricValues?: FabricValueData[];
  image?: boolean;
}

export interface Response {
  image?: string;
  modules?: ConstructorModule[];
  price: ProductPriceData;
  parameters?: Parameter[];
}

async function getInfoByParams(params: Params, opts?: RequestInit) {
  const url = '/backend/shop-product/info-by-params';
  const res = await Api.queryProxi<ApiResponse<Response>>(url, {
    ...opts,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...params, price: true }),
  });

  if (!res?.ok) throw new BackendError(url, res);

  return res.data;
}

export default getInfoByParams;
