import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

interface Params {
  productId: number;
}

async function getDeliveryDate(params: Params) {
  const url = `/backend/product/get-delivery-date/${params.productId}`;
  const res = await Api.queryProxi<ApiResponse<string[]>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getDeliveryDate;
