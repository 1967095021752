import { memo } from 'react';

import type { MaterialData } from '@Types/Product';
import type { FC } from 'react';

export interface TripleCircleProps {
  className?: string;
  materials: MaterialData[];
  id: number;
}

const TripleCircle: FC<TripleCircleProps> = (props) => {
  const { materials, id, ...restProps } = props;
  const image1 = materials[0].image;
  const image2 = materials[1].image;
  const image3 = materials[2].image;

  return (
    <svg
      {...restProps}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <circle cx='8' cy='8' r='8' fill='#C4C4C4' />
      </mask>
      <g mask='url(#mask0)'>
        <path
          d='M16 8C16 3.58172 12.4183 4.10854e-07 8 6.03983e-07C3.58172 7.97112e-07 -5.4282e-07 3.58172 -3.49691e-07 8C-1.56562e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8Z'
          fill={`url(#${id}-image1`}
        />
        <rect y='-0.592285' width='8' height='16.5926' fill={`url(#${id}-image2`} />
        <rect
          x='8'
          y='-4.74072'
          width='9.92443'
          height='9.92443'
          transform='rotate(45 8 -4.74072)'
          fill={`url(#${id}-image3`}
        />
      </g>
      <defs>
        <pattern id={`${id}-image1`} patternUnits='userSpaceOnUse' width='16' height='16'>
          <image href={image1} x='0' y='0' width='16' height='16' />
        </pattern>
        <pattern id={`${id}-image2`} patternUnits='userSpaceOnUse' width='8' height='16.5926'>
          <image href={image2} x='0' y='0' width='16' height='16' />
        </pattern>
        <pattern
          id={`${id}-image3`}
          patternUnits='userSpaceOnUse'
          width='9.92443'
          height='9.92443'
          transform='rotate(45 8 -4.74072)'
        >
          <image href={image3} x='0' y='0' width='16' height='16' />
        </pattern>
      </defs>
    </svg>
  );
};

export default memo(TripleCircle);
