import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { PromptData } from '@Stores/Onboarding';
import type { ApiResponse } from '@Types/Api';

export interface Params {
  shopProductId: number;
}

async function getOnboardingInfo(params: Params) {
  const url = `/backend/product/get-onboarding-info/${params.shopProductId}`;
  const res = await Api.queryProxi<ApiResponse<PromptData[]>>(url);

  if (!res?.ok) throw new BackendError(url, res);

  return res.data;
}

export default getOnboardingInfo;
