import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { ConfiguratorData, ConstructorValueData } from '@Pages/PageProduct';

export interface Params {
  shopProductId: number;
  parameterValues?: ConstructorValueData[];
}

async function getConfigurator(params: Params, opts?: RequestInit) {
  const url = '/backend/product/configurator-on-page';

  const res = await Api.queryProxi<ApiResponse<ConfiguratorData>>(url, {
    ...opts,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res?.ok) throw new BackendError(url, res);

  return res.data;
}

export default getConfigurator;
