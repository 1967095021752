import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';

export interface WarehouseData {
  shortDescription: string;
  description: string;
  address: string;
  workSchedule: string[][];
  latitude: string;
  longitude: string;
  quantity: number;
}

interface Params {
  productId: number;
}

async function getDeliveryPickup(params: Params) {
  const url = `/backend/product/get-delivery-pickup/${params.productId}`;
  const res = await Api.queryProxi<ApiResponse<WarehouseData>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getDeliveryPickup;
