import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ProductData } from '@Types/Product';
import type { ApiResponse } from '@Types/Api';

export interface Response {
  products: ProductData[];
}

async function getProducts(productIds: number[] | string[]) {
  const searchParams = new URLSearchParams({ productIds: `[${productIds.join()}]` });
  const searchString = searchParams.toString();
  const qs = searchString ? `?${searchString}` : searchString;
  const url = `/backend/layout/get-products${qs}`;
  const res = await Api.queryProxi<ApiResponse<Response>>(url);

  if (!res.ok) throw new BackendError(url, res);

  return res.data.products;
}

export default getProducts;
