import { memo, useCallback } from 'react';
import { cn, List } from '@divlab/divanui';

import Link from '@Navigation/Link';
import Img from '@UI/Img';
import TripleCircle from './elems/TripleCircle';
import DoubleCircle from './elems/DoubleCircle';
import styles from './Fabrics.module.css';

import type { ColorData } from '@Types/Product';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface FabricsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  fabrics: ColorData[];
  count?: number;
  defaultSelectedFabric?: ColorData;
  size?: 'xs' | 's' | 'm' | 'l';
  imageLazyLoading?: boolean;
  isLink?: boolean;
  onChangeProduct?: (id: number) => void;
  onVariantsLoad?: () => void;
  onClickColor?: (e: MouseEvent, clickedFabric: ColorData) => void;
  onClickConstructor?: () => void;
}

const Fabrics: FC<FabricsProps> = (props) => {
  const {
    className,
    fabrics,
    defaultSelectedFabric,
    size = 's',
    count,
    imageLazyLoading,
    isLink = true,
    onChangeProduct,
    onClickColor,
    onClickConstructor,
    ...restProps
  } = props;
  const handleChangeProduct = useCallback(
    (id: number) => {
      if (onChangeProduct) onChangeProduct(id);
    },
    [onChangeProduct],
  );

  const handleClickColor = useCallback(
    (e: MouseEvent, clickedFabric: ColorData) => {
      if (onClickColor) onClickColor(e, clickedFabric);
    },
    [onClickColor],
  );

  return (
    <List
      {...restProps}
      className={cn(
        styles.fabrics,
        {
          [styles.sizeXS]: size === 'xs',
          [styles.sizeS]: size === 's',
          [styles.sizeM]: size === 'm',
          [styles.sizeL]: size === 'l',
        },
        className,
      )}
      items={fabrics}
      renderChild={(fabric: ColorData, index) => {
        const isLast = index === fabrics.length - 1;

        return (
          <div
            className={cn(styles.fabric, {
              [styles.selected]: fabric === defaultSelectedFabric,
              [styles.disable]: !isLink,
            })}
          >
            <Link
              className={styles.fabricContainer}
              to={isLink ? fabric.link : undefined}
              onMouseEnter={() => handleChangeProduct(fabric.shopProductId)}
              onClick={(e: MouseEvent) => handleClickColor(e, fabric)}
              data-testid={`color${index + 1}`}
            >
              {/* TODO: Delete the condition with the materials array check after the release. It is necessary for a seamless transition to a new format */}
              {fabric.materials?.length > 0 ? (
                (() => {
                  switch (fabric.materials.length) {
                    case 1: {
                      return (
                        <Img
                          className={styles.imageWrapper}
                          src={fabric.materials[0].image}
                          alt={fabric.alt}
                          loading={imageLazyLoading ? 'lazy' : 'eager'}
                        />
                      );
                    }

                    case 2: {
                      return (
                        <div className={styles.imageWrapper}>
                          <DoubleCircle
                            id={fabric.shopProductId}
                            materials={fabric.materials}
                            className={styles.circleSvg}
                          />
                        </div>
                      );
                    }

                    case 3: {
                      return (
                        <div className={styles.imageWrapper}>
                          <TripleCircle
                            id={fabric.shopProductId}
                            materials={fabric.materials}
                            className={styles.circleSvg}
                          />
                        </div>
                      );
                    }

                    default:
                      return null;
                  }
                })()
              ) : (
                <Img
                  className={styles.imageWrapper}
                  src={fabric.image}
                  alt={fabric.alt}
                  loading={imageLazyLoading ? 'lazy' : 'eager'}
                />
              )}
            </Link>
            {isLast && !!count && (
              <div
                className={styles.counter}
                onClick={onClickConstructor}
                data-testid='link-to-constructor-2'
              >
                {`+ ${count}`}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default memo(Fabrics);
