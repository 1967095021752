import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { OrderFabricsData } from '@Stores/OrderFabrics';

export interface Params {
  id: number;
}

export interface Result {
  ok: boolean;
  data: OrderFabricsData;
}

async function getFabricSamples({ id }: Params): Promise<OrderFabricsData> {
  const url = `/backend/product/fabric-samples/${id}`;
  const res = await Api.queryProxi<Result>(url);

  if (!res.ok) throw new BackendError(url, res);
  return res.data;
}

export default getFabricSamples;
