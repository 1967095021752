import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { SellPointData } from '@Types/SellPoints';
import type { ApiResponse } from '@Types/Api';
import type { MapData } from '@Types/Map';

export interface Response {
  map: MapData;
  sellPoints: SellPointData[];
}

async function getProductSellPoint(id: number) {
  const url = `/backend/product/sell-points/${id}`;
  const res = await Api.queryProxi<ApiResponse<Response>>(url, { method: 'GET' });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getProductSellPoint;
