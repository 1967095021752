import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ApiResponse } from '@Types/Api';
import type { ProductPriceData } from '@Types/Product';

export interface SelectedParametersData {
  parameterValues: number[];
}

export interface Params {
  typeIds: number[];
  selectedParameters: SelectedParametersData;
}

export interface ParameterItemData {
  id: number;
  parameterName: string;
  value: string | number;
  selected: boolean;
  price?: number;
}

export interface ParameterData {
  groupId: number;
  groupName: string;
  title?: string;
  items: ParameterItemData[][];
  unit?: string;
}

export interface FabricData {
  id: number;
  name: string;
  isActive: boolean;
  image: string;
  fullImage: string;
  selected: boolean;
}

export interface FabricsData {
  title?: string;
  items: FabricData[];
}

interface TypeData {
  name: string;
  id: number;
}

export interface PickPairData {
  id: number;
  type: TypeData;
  note?: string;
  name: string;
  parameters: ParameterData[];
  productParameters?: ParameterData[];
  fabrics: FabricsData;
  price: ProductPriceData;
  productImage: string;
  isActive: boolean;
  warning?: string;
  typeIds: number[];
}

export interface ActionData {
  title: string;
  product: PickPairData;
  active?: boolean;
}

export interface AdditionalData {
  products?: PickPairData[];
  actions: ActionData[];
}

export interface SetData {
  products: PickPairData[];
  additional: AdditionalData;
}

async function getSetItem(params: Params) {
  const url = '/backend/configurator/get-set-item';

  const res = await Api.queryProxi<ApiResponse<PickPairData>>(url, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data;
}

export default getSetItem;
