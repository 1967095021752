import * as Api from '@Api/index';
import { BackendError } from '@Common/errors';

import type { ProductData } from '@Types/Product';
import type { ApiResponse } from '@Types/Api';

export interface Params {
  ids: number[];
  // нужен для определения категории и нужно ли у нее схлопывать размеры
  // если категория не передана или не подойдет под условия, то ответ будет без ключа variants
  slug?: string;
}

async function getProductVariantInfo(params: Params, opts?: RequestInit) {
  const url = '/backend/data/get-product-variant-info';
  const res = await Api.queryProxi<ApiResponse<ProductData[]>>(url, {
    ...(opts || {}),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  if (!res.ok) throw new BackendError(url, res);

  return res.data || [];
}

export default getProductVariantInfo;
